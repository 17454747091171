import { NgModule } from '@angular/core';
import { MeetingsComponent } from './components/meetings/meetings.component';
import { SharedModule } from '../../shared/shared.module';
import { MeetingsListComponent } from './components/meetings-list/meetings-list.component';
import { MeetingsRoutingModule } from './meetings-routing.module';
import { MeetingsNewComponent } from './components/meetings-new/meetings-new.component';
import { MeetingsProfileComponent } from './components/meetings-profile/meetings-profile.component';

@NgModule({
  declarations: [
    MeetingsComponent, 
    MeetingsListComponent,
    MeetingsNewComponent,
    MeetingsProfileComponent,
  ],
  imports: [
    SharedModule,
    MeetingsRoutingModule
  ]
})
export class MeetingsModule { }
