import { Component, Input, OnInit } from '@angular/core';
import { MeetingSchedulingData } from '../../../../shared/models/meetings.model';

@Component({
  selector: 'app-meetings-new',
  templateUrl: './meetings-new.component.html',
  styleUrl: './meetings-new.component.scss'
})
export class MeetingsNewComponent {

  @Input() schedulingData!: MeetingSchedulingData;

  public states = {
    selectedTab: 'default'
  };
}
