import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { MeetingSchedulingData } from '../../../../shared/models/meetings.model';
import { HubspotMeetingsService } from '../../../../shared/services/hubspot/hubspot-meetings.service';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrl: './meetings.component.scss'
})
export class MeetingsComponent implements OnInit {
  public schedulingData: MeetingSchedulingData | null = null;
  public states = {
    loadingSchedulingData: false,
  };

  public meetingContactSelectionForm = new FormGroup({
    contactEmail: new FormControl(''),
  });

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _meetingsService: HubspotMeetingsService,
    private _toastr: ToastrService) {}

  ngOnInit(): void {
    // const fromEmail = this._route.snapshot.queryParamMap.get('contato');

    // if (fromEmail != null) {
    //   this.meetingContactSelectionForm.get('contactEmail')?.setValue(fromEmail);
    //   this.sendMeetingContactSelectionForm();
    // }

    this._route.queryParamMap.subscribe(params => {
      const fromEmail = params.get('contato');
      this.schedulingData = null;
      this.meetingContactSelectionForm.get('contactEmail')?.setValue(fromEmail);

      if (fromEmail != null) {
        this.sendMeetingContactSelectionForm();
      }
    });
  }

  public onOutletLoaded(component: any): void {
    component.schedulingData = this.schedulingData;
  }

  public sendMeetingContactSelectionForm(): void {
    const contactEmail = this.meetingContactSelectionForm.get('contactEmail')?.value;

    if (!contactEmail) {
      this._toastr.error('Ops! O e-mail do contato é obrigatório.');
      return;
    }

    this.getSchedulingData(contactEmail, () => {
      if((this.schedulingData?.meetings?.length ?? 0) > 0) {
        this._router.navigate(['/reunioes/lista'], {
          queryParams: { contato: contactEmail },
          queryParamsHandling: 'merge',
        });
      } else {
        this._router.navigate(['/reunioes/nova'], {
          queryParams: { contato: contactEmail },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  public getSchedulingData(contact_email: string, callback: Function = () => {}): void {
    this.states.loadingSchedulingData = true;
    this._meetingsService.getMeetingScheduleData(contact_email).subscribe(data => {
      this.schedulingData = data;
      this.states.loadingSchedulingData = false;
      callback();
    }, error => {
      this.states.loadingSchedulingData = false;
      if (error.error?.error) {
        this._toastr.error(error.error.error);
      } else {
        this._toastr.error('Ops! Falha ao carregar os dados de agendamento de reunião.');
      }
    });
  }
}
