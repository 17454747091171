import { Component, Input } from '@angular/core';
import { MeetingSchedulingData } from '../../../../shared/models/meetings.model';

@Component({
  selector: 'app-meetings-list',
  templateUrl: './meetings-list.component.html',
  styleUrl: './meetings-list.component.scss'
})
export class MeetingsListComponent {
  @Input() schedulingData!: MeetingSchedulingData;
}
