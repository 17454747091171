<div class="container schedule-meetings d-flex flex-column align-items-center justify-content-center">
  <!-- Botões -->
   <div class="mb-5 bg-light px-3 py-2 rounded-pill">
    <a class="btn mx-1 btn-secondary rounded-pill" href="">Reunião simples</a>
    <a class="btn btn-link mx-1 rounded-pill position-relative" href="">D-Hora <span class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-pink">Em breve</span></a>
   </div>

  <!-- Dados do cliente -->
  <div class="rounded-pill bg-light mb-5 py-4 px-5 w-50 d-flex justify-content-center align-items-center">
    <div>
      <h5>{{schedulingData.sdr.name}}</h5>
      <p class="mb-0">{{schedulingData.sdr.email}}</p>
    </div>
    <div class="mx-5">
      <i class="fa-thin fa-chevron-right fs-1"></i>
    </div>
    <div>
      <h5>{{schedulingData.contact.name}}</h5>
      <p class="mb-0">{{schedulingData.contact.email}}</p>
    </div>
  </div>

  <!-- Agendas -->
  <div class="d-flex w-100 justify-content-center">
    <div class="card w-25 shadow-sm m-3 position-relative">
      <div class="card-body text-center">
        <i class="fad fa-calendar-day h1 text-primary"></i>
        <h5>
          <a [href]="schedulingData.contact.schedule_links.default" class="stretched-link text-primary">Marcar reunião de 1h</a>
        </h5>
        <small class="rounded-pill bg-warning text-sm px-3 py-1">Todos os produtos</small>
        <p class="mt-3">Para um vendedor aleatório com base no revezamento de RDs</p>
      </div>
    </div>

    <div class="card w-25 shadow-sm m-3">
      <div class="card-body text-center">
        <span class="fad fa-calendar-day h1 text-primary"></span>
        <h5>
          <a [href]="schedulingData.contact.schedule_links.invoice_product" class="stretched-link text-primary">Marcar reunião de 1h</a>
        </h5>
        <small class="rounded-pill bg-warning text-sm px-3 py-1">Emissor</small>
        <p class="mt-3">Para um vendedor aleatório com base no revezamento de RDs</p>
      </div>
    </div>

    <div class="card w-25 shadow-sm m-3 opacity-75">
      <div class="card-body text-center">
        <span class="fad fa-calendar-day h1 text-muted"></span>
        <h5>
          <a href="https://calendly.com/d/cmg3-5cj-shb/teste-de-revezamento?name={{schedulingData.contact.name}}&email={{schedulingData.contact.email}}&guests={{schedulingData.sdr.email}}&a1=123456" class="stretched-link text-muted">Reunião para Treinamento</a>
        </h5>
        <small class="rounded-pill bg-muted text-sm px-3 py-1">Uso interno</small>
        <p class="mt-3">Agenda para realizar testes e treinamentos de equipe.</p>
      </div>
    </div>


  </div>
</div>
