class MeetingSchedulingDataPerson {
  email!: string;
  name!: string;
}

class MeetingSchedulingDataContactScheduleLinks {
  default!: string;
  open_finance_product!: string;
  invoice_product!: string;
}

class MeetingSchedulingDataContact {
  email!: string;
  name!: string;
  schedule_links!: MeetingSchedulingDataContactScheduleLinks;
  hubspot_id!: string;
}

class MeetingSchedulingDataMeeting {
  cancel_url!: string;
  reschedule_url!: string;
  end_time!: string;
  start_time!: string;
  owner!: MeetingSchedulingDataPerson;
  sdr!: MeetingSchedulingDataPerson;

  get isPast(): boolean {
    return new Date(this.start_time) < new Date();
  }

  constructor(data: object) {
    Object.assign(this, data);
  }
}

export class MeetingSchedulingData {
  public sdr: MeetingSchedulingDataPerson;
  public contact: MeetingSchedulingDataContact;
  public meetings: MeetingSchedulingDataMeeting[];

  constructor(data: any) {
    this.sdr = new MeetingSchedulingDataPerson();
    this.contact = new MeetingSchedulingDataContact();
    this.meetings = [];

    Object.assign(this, data);

    if (data.hasOwnProperty('meetings')) {
      this.meetings = (data['meetings'] as object[]).map(meetingData => new MeetingSchedulingDataMeeting(meetingData));
    }
  }
}
