import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MeetingsComponent } from './components/meetings/meetings.component';
import { MeetingsListComponent } from './components/meetings-list/meetings-list.component';
import { MeetingsNewComponent } from './components/meetings-new/meetings-new.component';

const routes: Routes = [
  {
    path: '',
    component: MeetingsComponent,
    children: [
      {
        path: 'lista',
        component: MeetingsListComponent,
      },
      {
        path: 'nova',
        component: MeetingsNewComponent,
      },
    ]
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MeetingsRoutingModule {}
