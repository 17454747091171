<!-- Empty state -->
<div *ngIf="!schedulingData" class="container inner-shadow container-empty-state d-flex justify-content-center flex-column align-items-center">
  <form [formGroup]="meetingContactSelectionForm" class="text-center" (submit)="sendMeetingContactSelectionForm()">

    <p><i class="fa-duotone fa-solid display-1 text-primary fa-calendar-clock"></i></p>

    <h3 class="text-primary">Agendar ou alterar reunião</h3>

    <p class="lead">Agende ou altere uma reunião para um contato já registrado no Hubspot. O contato deverá estar associado a um negócio em andamento no Pipeline de pré-vendas.</p>

    <input type="email" class="form-control form-control-lg" required id="inputName" placeholder="exemplo@gmail.com" formControlName="contactEmail">

    <div class="form-row mt-3">
      <div class="form-group">
        <button type="submit" [disabled]="!meetingContactSelectionForm.valid || states.loadingSchedulingData" class="btn  btn-secondary">{{states.loadingSchedulingData ? 'Aguarde...' : 'Buscar contato'}}</button>
      </div>
    </div>
  </form>
</div>


<div *ngIf="schedulingData" class="container-fluid h-100 inner-shadow pt-3">
  <div class="row h-100">
    <div class="col-2">

      <app-meetings-profile [schedulingData]="schedulingData"></app-meetings-profile>

      <ul class="meeting-menu nav flex-column nav-pills mt-4">
        <li class="nav-item meeting-menu-item"><a routerLink="/reunioes/lista/" [queryParams]="{contato: schedulingData.contact.email}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"><i class="fa-duotone fa-solid fa-calendars me-2"></i> Todas as reuniões <span *ngIf="schedulingData.meetings.length" class="badge bg-pink rounded-pill">{{schedulingData.meetings.length}}</span></a></li>
        <li class="nav-item meeting-menu-item"><a routerLink="/reunioes/nova/" [queryParams]="{contato: schedulingData.contact.email}"  routerLinkActive="active" class="nav-link"><i class="fa-duotone fa-solid fa-calendar-circle-plus me-2"></i> Nova reunião</a></li>
      </ul>
    </div>

    <div class="col">
      <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
    </div>
  </div>
</div>
