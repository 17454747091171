import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MeetingSchedulingData } from '../../models/meetings.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HubspotMeetingsService {

  constructor(private _http: HttpClient) { }

  public getMeetingScheduleData(contactEmail: string): Observable<MeetingSchedulingData> {
    return this._http.get<any>(`${environment.salesAPI}/hubspot/contacts/${contactEmail}/meeting/schedule`)
          .pipe(map(data => new MeetingSchedulingData(data)));
  }
}
