import { Component, Input } from '@angular/core';
import { MeetingSchedulingData } from '../../../../shared/models/meetings.model';

@Component({
  selector: 'app-meetings-profile',
  templateUrl: './meetings-profile.component.html',
  styleUrl: './meetings-profile.component.scss'
})
export class MeetingsProfileComponent {
  @Input() schedulingData!: MeetingSchedulingData;
}
